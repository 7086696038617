import {useFormikContext} from 'formik';
import {uniqueId} from 'lodash';
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {
    InputGroup,
    InputField as StyledField,
} from 'shared/components/StyledComponents';

interface InputFieldProps {
    name: string;
    label: string;
    type?: string;
    placeholder?: string;
    children?: JSX.Element;
    required?: boolean;
    labelWidth?: string;
    as?: string;
    disabled?: boolean;
}

interface GenericType {
    [key: string]: string;
}

// reusable wrapper component
export const WithTooltipWrapper = ({
    children,
    tooltip,
    show,
}: {
    children?: JSX.Element;
    tooltip: string;
    show: boolean;
}) => {
    return (
        <OverlayTrigger
            trigger={show ? ['hover', 'click'] : []}
            placement="right"
            flip
            overlay={
                <Tooltip
                    id={uniqueId('tooltip')}
                    className="error-popover"
                    style={{...(show ? {} : {display: 'none'})}}>
                    {tooltip}
                </Tooltip>
            }>
            <div style={{flex: 1}}>{children}</div>
        </OverlayTrigger>
    );
};

// eslint-disable-next-line react/no-multi-comp
const InputFieldWithTooltip = ({
    name,
    label,
    placeholder,
    type = 'text',
    children,
    required = false,
    labelWidth = '170px',
    as,
    disabled,
}: InputFieldProps) => {
    const {touched, errors} = useFormikContext<Partial<GenericType>>();
    return (
        <InputGroup $required={required} $labelWidth={labelWidth}>
            <label>{label}</label>
            <WithTooltipWrapper
                show={!!touched[String(name)] && !!errors[String(name)]}
                tooltip={errors[String(name)]}>
                <StyledField
                    name={name}
                    data-cy={name}
                    type={type}
                    placeholder={placeholder}
                    $invalid={!!touched[String(name)] && !!errors[String(name)]}
                    component={as}
                    disabled={disabled}
                />
            </WithTooltipWrapper>
            {children}
        </InputGroup>
    );
};

export default InputFieldWithTooltip;
